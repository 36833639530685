<template>
  <card-modal
    icon="fal fa-badge-percent"
    :title="$t('offer_promotions.create_action.title')"
    context="AddOfferPromotion"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="offer_promotions.create_action.cancel"
    submit_text="offer_promotions.create_action.submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <h4>{{ $t('offer_promotions.fields.incentive_title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="reward_percent"
              :label="$t('offer_promotions.fields.reward_percent') + '*'"
              name="reward_percent"
              append-icon="fas fa-percentage"
              v-validate="'required|integer|min_value:1|max_value:100'"
              :error-messages="errors.collect('reward_percent')"
              data-vv-name="reward_percent"
              :data-vv-as="$t('offer_promotions.fields.reward_percent')"
              data-test="offers-offer-promotions--reward_percent"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="user_inventory_limit"
              :label="$t('offer_promotions.fields.user_inventory_limit') + '*'"
              name="incentive.user_inventory_limit"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                min_value: 1,
                max_value: 20000,
              }"
              :error-messages="errors.collect('incentive.user_inventory_limit')"
              data-vv-name="incentive.user_inventory_limit"
              :data-vv-as="$t('offer_promotions.fields.user_inventory_limit')"
              data-test="offers-offer-promotions--incentive.user_inventory_limit"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <label-text :label="$t('offer_promotions.fields.user_payment_limit')" :value="userPaymentLimit | currency | dollarSignI18n"></label-text>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="minimum_payment"
              :label="$t('offer_promotions.fields.minimum') + '*'"
              name="incentive_min"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                min_value: 1,
                minimumPaymentBetween: { field1: userPaymentLimit, field2: default_payment },
              }"
              :error-messages="errors.collect('incentive_min')"
              data-vv-name="incentive_min"
              :data-vv-as="$t('offer_promotions.fields.minimum')"
              data-test="offers-offer-promotions--incentive_min"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="step"
              :label="$t('offer_promotions.fields.step') + '*'"
              name="incentive_step"
              append-icon="fas fa-dollar-sign"
              v-validate="{ required: true, integer: true, min_value: 1, stepBetween: { field1: userPaymentLimit } }"
              :error-messages="errors.collect('incentive_step')"
              data-vv-name="incentive_step"
              :data-vv-as="$t('offer_promotions.fields.step')"
              data-test="offers-offer-promotions--incentive_step"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="default_payment"
              :label="$t('offer_promotions.fields.default_payment') + '*'"
              name="incentive.default_payment"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                defaultPaymentBetween: { field1: minimum_payment, field2: userPaymentLimit },
                withinMinStep: { min: minimum_payment, step: step },
              }"
              :error-messages="errors.collect('incentive.default_payment')"
              data-vv-name="incentive.default_payment"
              :data-vv-as="$t('offer_promotions.fields.default_payment')"
              data-test="offers-offer-promotions--incentive.default_payment"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-select
              :items="durationModelOptions"
              name="duration_model"
              v-validate="'required'"
              v-model="duration_model"
              :label="$t('offer_promotions.fields.duration_model') + '*'"
              :error-messages="errors.collect('duration_model')"
              data-vv-name="duration_model"
              :data-vv-as="$t('offer_promotions.fields.duration_model')"
              item-text="name"
              item-value="id"
              data-test="offers-offer-promotion--duration_model"
            ></v-select>
          </v-flex>
          <v-flex sm8 xs12>
            <v-text-field
              v-if="duration_model === 'relative'"
              v-model="days"
              :label="$t('offer_promotions.fields.duration') + '*'"
              :hint="$t('offer_promotions.fields.duration_hint')"
              name="incentive_duration"
              v-validate="{
                required: duration_model === 'relative',
                integer: true,
              }"
              :error-messages="errors.collect('incentive_duration')"
              data-vv-name="incentive_duration"
              :data-vv-as="$t('offer_promotions.fields.duration')"
              data-test="offers-offer-promotion--incentive_duration"
              required
            ></v-text-field>
            <v-menu
              v-if="duration_model === 'fixed'"
              v-model="showDisplayDurationEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="duration_end_date"
                      v-validate="{ required: duration_model === 'fixed' }"
                      data-vv-name="duration_end_date"
                      :error-messages="errors.collect('duration_end_date')"
                      :data-vv-as="$t('offer_promotions.fields.duration_end_date')"
                      :label="$t('offer_promotions.fields.duration_end_date') + '*'"
                      data-test="offers-offer-promotion--duration_end_date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="duration_end_date"
                @input="showDisplayDurationEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>

        <h4>{{ $t('offer_promotions.fields.availability_title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm9 xs12>
            <h-autocomplete
              v-model="inventory_id"
              :label="$t('offer_promotions.fields.inventory') + '*'"
              v-validate="'required'"
              :error-messages="errors.collect('inventory')"
              name="inventory"
              :alternate-text="inventoryLookupItemText"
              data-vv-name="inventory"
              data-vv-as="inventory"
              search-action="inventory/autocompleteSearch"
              data-test="offers-offer-promotions--inventory"
              no-filter
            >
            </h-autocomplete>
          </v-flex>
          <v-flex sm3 xs12>
            <v-checkbox
              name="refillable"
              v-model="is_refillable"
              :label="$t(`offer_promotions.fields.is_refillable`)"
              :error-messages="errors.collect('refillable')"
              data-vv-name="refillable"
              :data-vv-as="$t(`offer_promotions.fields.is_refillable`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm12>
            <v-menu
              v-model="showDisplayStartDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="display_start_date"
                      v-validate="'required'"
                      data-vv-name="display_start_date"
                      :error-messages="errors.collect('display_start_date')"
                      :data-vv-as="$t('offer_promotions.fields.display_start_date')"
                      :label="$t('offer_promotions.fields.display_start_date') + '*'"
                      data-test="offers-offer-promotion--display_start_date"
                      prepend-icon="event"
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutes"
                      name="display_start_time"
                      v-validate="'required'"
                      v-model="display_start_time"
                      :label="$t('offer_promotions.fields.display_start_time')"
                      :error-messages="errors.collect('display_start_time')"
                      data-vv-name="display_start_time"
                      :data-vv-as="$t('offer_promotions.fields.display_start_time')"
                      prepend-icon="far fa-clock"
                      data-test="offers-offer-promotion--display_start_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="display_start_date"
                @input="showDisplayStartDatePickerCalendar = false"
                :show-current="new Date().toISOString().substring(0, 10)"
                :max="start_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm12>
            <v-menu
              v-model="showStartDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="start_date"
                      data-vv-name="start_date"
                      v-validate="{ required: true, islaterOrSame: { date: display_start_date } }"
                      :error-messages="errors.collect('start_date')"
                      :data-vv-as="$t('offer_promotions.fields.start_date')"
                      :label="$t('offer_promotions.fields.start_date') + '*'"
                      data-test="offers-offer-promotion--start_date"
                      prepend-icon="event"
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutes"
                      name="start_time"
                      v-validate="'required'"
                      v-model="start_time"
                      :label="$t('offer_promotions.fields.start_time')"
                      :error-messages="errors.collect('start_time')"
                      data-vv-name="start_time"
                      :data-vv-as="$t('offer_promotions.fields.start_time')"
                      prepend-icon="far fa-clock"
                      data-test="offers-offer-promotion--start_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="start_date"
                @input="showStartDatePickerCalendar = false"
                :show-current="new Date().toISOString().substring(0, 10)"
                :min="display_start_date"
                :max="end_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm12>
            <v-menu
              v-model="showEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="end_date"
                      data-vv-name="end_date"
                      :error-messages="errors.collect('end_date')"
                      v-validate="{ required: true, islater: { date: start_date } }"
                      :data-vv-as="$t('offer_promotions.fields.end_date')"
                      :label="$t('offer_promotions.fields.end_date') + '*'"
                      data-test="offers-offer-promotions--end_date"
                      prepend-icon="event"
                      readonly
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutesEnd"
                      name="end_time"
                      v-validate="'required'"
                      v-model="end_time"
                      :label="$t('offer_promotions.fields.end_time')"
                      :error-messages="errors.collect('end_time')"
                      data-vv-name="end_time"
                      :data-vv-as="$t('offer_promotions.fields.end_time')"
                      prepend-icon="far fa-clock"
                      data-test="offers-offer-promotion--end_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="end_date"
                @input="showEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
                :min="start_date"
                :max="display_end_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 sm12>
            <v-menu
              v-model="showDisplayEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="display_end_date"
                      data-vv-name="display_end_date"
                      :error-messages="errors.collect('display_end_date')"
                      v-validate="{ required: true, islaterOrSame: { date: end_date } }"
                      :data-vv-as="$t('offer_promotions.fields.display_end_date')"
                      :label="$t('offer_promotions.fields.display_end_date') + '*'"
                      data-test="offers-offer-promotions--display_end_date"
                      prepend-icon="event"
                      readonly
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutesEnd"
                      name="display_end_time"
                      v-validate="'required'"
                      v-model="display_end_time"
                      :label="$t('offer_promotions.fields.display_end_time')"
                      :error-messages="errors.collect('display_end_time')"
                      data-vv-name="display_end_time"
                      :data-vv-as="$t('offer_promotions.fields.display_end_time')"
                      prepend-icon="far fa-clock"
                      data-test="offers-offer-promotion--display_end_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="display_end_date"
                @input="showDisplayEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
                :min="end_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>

        <h4>{{ $t('offer_promotions.fields.accounting_title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-text-field
            :label="$t('offer_promotions.fields.accounting_reference_id')"
            name="accounting_reference_id"
            v-validate="'max:100'"
            :error-messages="errors.collect('accounting_reference_id')"
            v-model="accounting_reference_id"
            data-vv-label="accounting_reference_id"
            maxlength="100"
            :data-vv-as="$t('offer_promotions.fields.accounting_reference_id')"
            data-test="offers-offer-promotion--accounting_reference_id"
          >
          </v-text-field>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <h-autocomplete
            v-model="bonus_breakage_owner_business_id"
            :label="$t('offer_promotions.fields.breakage_bonus_owner')"
            :error-messages="errors.collect('bonus_breakage_owner_business_id')"
            name="bonus_breakage_owner_business_id"
            data-vv-name="bonus_breakage_owner_business_id"
            :data-vv-as="$t('offer_promotions.fields.breakage_bonus_owner')"
            search-action="business/autocompleteSearch"
            data-test="offers-offer-promotion--bonus_breakage_owner_business_id"
            clearable
          >
          </h-autocomplete>
        </v-layout>

        <h4>{{ $t('offer_promotions.fields.funding_title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm4>
            <v-select
              :items="fundingTriggerOptions"
              name="bonus_trigger"
              v-validate="'required'"
              v-model="trigger"
              :label="$t('offer_promotions.fields.funding_trigger') + '*'"
              :error-messages="errors.collect('bonus_trigger')"
              data-vv-name="bonus_trigger"
              :data-vv-as="$t('offer_promotions.fields.funding_trigger')"
              item-text="name"
              item-value="id"
              data-test="offers-offer-promotion--bonus_trigger"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <h-autocomplete
            :readonly="trigger === 'upfront'"
            v-model="contributor_business_id"
            v-validate="'required|max:100'"
            :label="$t('offer_promotions.fields.contributor') + '*'"
            :error-messages="errors.collect('contributor_business_id')"
            name="contributor_business_id"
            data-vv-name="contributor_business_id"
            :data-vv-as="$t('offer_promotions.fields.contributor')"
            search-action="business/autocompleteSearch"
            data-test="offers-offer-promotion--contributor_business_id"
          >
          </h-autocomplete>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { adjustValueForMinStep } from '@/utils/offer-utils';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import _ from 'lodash';

export default {
  name: 'add-offer-promotion-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerId: {
      type: String,
    },
  },
  data() {
    return {
      accounting_reference_id: null,
      start_date: null,
      start_time: '00:00',
      end_date: null,
      end_time: '00:00',
      display_start_date: null,
      display_start_time: '00:00',
      display_end_date: null,
      display_end_time: '00:00',
      minimum_payment: null,
      user_inventory_limit: null,
      // Bonus
      reward_percent: null,
      duration_model: 'relative',
      duration_end_date: null,
      duration_end_time: '00:00',
      days: null,
      // Step
      step: null,
      default_payment: null,
      // Availability
      inventory_id: null,
      is_refillable: true,
      // funding
      contributor_business_id: null,
      bonus_breakage_owner_business_id: null,
      trigger: 'transaction',

      // Support
      showStartDatePickerCalendar: false,
      showEndDatePickerCalendar: false,
      showDisplayStartDatePickerCalendar: false,
      showDisplayEndDatePickerCalendar: false,
      showDisplayDurationEndDatePickerCalendar: false,
    };
  },
  watch: {
    async trigger() {
      if (this.trigger === 'upfront') {
        this.contributor_business_id = process.env.VUE_APP_DEFAULT_UPFRONT_CONTRIBUTOR;
      } else {
        this.contributor_business_id = null;
      }
    },
  },
  computed: {
    ...mapGetters('offers', ['fundingTriggers', 'incentiveDurationModels']),
    userPaymentLimit() {
      return this.reward_percent && this.user_inventory_limit
        ? adjustValueForMinStep(this.minimum_payment, this.step, this.user_inventory_limit / (this.reward_percent / 100))
        : 0;
    },
    fundingTriggerOptions() {
      return _.map(this.fundingTriggers, (item) => ({
        name: this.$t(`offers.funding_triggers.${item}`),
        id: item,
      }));
    },
    durationModelOptions() {
      return _.map(this.incentiveDurationModels, (durationModel) => ({
        name: this.$t(`offers.incentive_duration_models.${durationModel}`),
        id: durationModel,
      }));
    },

    generateRangeOfThirtyMinutes() {
      return Array.from({ length: 24 }, (_, i) => i).reduce((r, hour) => {
        r.push(moment({ hour, minute: 0 }).format('HH:mm'));
        r.push(moment({ hour, minute: 30 }).format('HH:mm'));
        return r;
      }, []);
    },

    generateRangeOfThirtyMinutesEnd() {
      const result = Array.from({ length: 24 }, (_, i) => i).reduce((r, hour) => {
        r.push(
          moment({ hour, minute: 0 })
            .subtract(1, 'm')
            .format('HH:mm'),
        );
        r.push(
          moment({ hour, minute: 30 })
            .subtract(1, 'm')
            .format('HH:mm'),
        );
        return r;
      }, []);
      result.sort();
      return result;
    },
  },
  methods: {
    inventoryLookupItemText(it) {
      return `${it.name} - ${this.$options.filters.dollarSignI18n(this.$options.filters.currency(it.available_amount))}`;
    },

    clear() {
      this.accounting_reference_id = null;
      this.start_date = null;
      this.start_time = null;
      this.end_date = null;
      this.end_time = null;
      this.display_start_date = null;
      this.display_start_time = null;
      this.display_end_date = null;
      this.display_end_time = null;
      this.minimum_payment = null;
      this.user_inventory_limit = null;
      // Bonus
      this.reward_percent = null;
      this.duration_model = null;
      this.duration_end_date = null;
      this.duration_end_time = null;
      this.days = null;
      // Step
      this.step = null;
      this.default_payment = null;
      // Availability
      this.inventory_id = null;
      this.is_refillable = true;
      // funding
      this.contributor_business_id = null;
      this.bonus_breakage_owner_business_id = null;
      this.trigger = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = {
          type: 'bonus',
          offer_id: this.offerId,
          start_date: this.start_date,
          start_time: this.start_time,
          end_date: this.end_date,
          end_time: this.end_time,
          accounting_reference_id: _.isEmpty(this.accounting_reference_id) ? null : this.accounting_reference_id,
          display_start_date: this.display_start_date,
          display_start_time: this.display_start_time,
          display_end_date: this.display_end_date,
          display_end_time: this.display_end_time,
          bonus: {
            user_inventory_limit: this.user_inventory_limit,
            reward_percent: this.reward_percent,
            duration: {
              model: this.duration_model,
            },
            minimum_payment: this.minimum_payment,
            step: {
              step: this.step,
              default_payment: this.default_payment,
            },
            availability: {
              inventory_id: this.inventory_id,
              is_refillable: this.is_refillable,
            },
            funding: {
              contributor_business_id: this.contributor_business_id,
              bonus_breakage_owner_business_id: this.bonus_breakage_owner_business_id || null,
              trigger: this.trigger,
            },
          },
        };

        if (this.duration_model && this.duration_model !== 'fixed') {
          payload.bonus.duration.days = this.days;
        }
        if (this.duration_model && this.duration_model !== 'relative') {
          payload.bonus.duration.end_date = this.duration_end_date;
          payload.bonus.duration.end_time = this.duration_end_time;
        }

        await this.executeAction(
          {
            action: 'offers/addOfferPromotion',
            name: 'AddOfferPromotion',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
